<template>
    <div class="qar">
        <div class="qar__code">
            <img :src="require('@/assets/' + qar)" alt="qar">
        </div>
        <h3 class="qar__title">
            {{ title }}
        </h3>
    </div>
</template>

<script>
export default {
    name: "QarCod",
    props: {
        qar: String,
        title: String,
    }
}
</script>

<style scoped>

</style>