<template>
    <a href="#" target="_blank" class="download-app">
        {{ langs[getLang] }}
    </a>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "DownloadApp",
    data() {
        return {
            langs: {
                en: 'Download \n the App',
                es: 'Download \n the App',
                pt: 'Download \n the App',
            }
        }
    },
    computed: {
        ...mapGetters([
            "getLang"
        ])
    }
}
</script>

<style scoped>

</style>