<template>
    <button class="hamburger-button" :class="{'hamburger-button__active':active}" @click="$emit('update:active', !active)">
        <div class="hamburger-button__line"></div>
        <div class="hamburger-button__line"></div>
        <div class="hamburger-button__line"></div>
    </button>
</template>

<script>
export default {
    name: "HamburgerButton",
    emits: ["update:active"],
    props: {
        active: Boolean
    }
}
</script>

<style scoped>

</style>