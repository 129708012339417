<template>
    <section class="section-contact" id="contacts">
        <div class="wrap section-contact__wrap">
            <div class="section-contact__qar">
                <DownloadApp/>
                <div class="section-contact__qar-section">
                    <QarCod :qar="'xIoT-IOS.svg'" :title="'IOS'"/>
                    <QarCod :qar="'xIoT-Android.svg'" :title="'Android'"/>
                </div>
            </div>
            <div class="section-contact__info">
                <div class="section-contact__info-content">
                    <h3 class="section-contact__info-title">
                        Support Email:
                    </h3>
                    <a class="section-contact__info-link" href="#">
                        helpdesk@telematixs.net
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "ContactComponent",
    components: {}
}
</script>

<style scoped>

</style>