import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { VueReCaptcha } from 'vue-recaptcha-v3'
const app = createApp(App)

app.use(store)
app.use(router)

app.use(VueReCaptcha, {
    siteKey: '6Le5-HcjAAAAAEPmmmk7otSMI8lJS7GTaMyvKkYZ',
    loaderOptions: {
        useRecaptchaNet: true,
        autoHideBadge: false,
        explicitRenderParameters: {
            badge: "bottomleft",
        }
    }
})

app.component('HeaderComponent', HeaderComponent);
import HeaderComponent from "@/components/HeaderComponent.vue";
app.component('MainSection', MainSection);
import MainSection from "@/components/MainSection.vue";
app.component('ProductDescription', ProductDescription);
import ProductDescription from "@/components/ProductDescription.vue";
app.component('LogoSlider', LogoSlider);
import LogoSlider from "@/components/LogoSlider.vue";
app.component('TitleComponent', TitleComponent);
import TitleComponent from "@/components/TitleComponent.vue";
app.component('ProductInfo', ProductInfo);
import ProductInfo from "@/components/ProductInfo.vue";
app.component('FooterComponent', FooterComponent);
import FooterComponent from "@/components/FooterComponent.vue";
app.component('ContactComponent', ContactComponent);
import ContactComponent from "@/components/ContactComponent.vue";
app.component('ContactForm', ContactForm);
import ContactForm from "@/components/ContactForm.vue";

app.component('CustomSelect', CustomSelect);
import CustomSelect from "@/components/custom/customSelect.vue";
app.component('SocialNetwork', SocialNetwork);
import SocialNetwork from "@/components/custom/SocialNetwork.vue";
app.component('DownloadApp', DownloadApp);
import DownloadApp from "@/components/custom/DownloadApp.vue";
app.component('QarCod', QarCod);
import QarCod from "@/components/custom/QarCod.vue";
app.component('HamburgerButton', HamburgerButton);
import HamburgerButton from "@/components/custom/HamburgerButton.vue";

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
app.use(Toast, {
    "optons": {
        "transition": "Vue-Toastification__bounce",
        "maxToasts": 20,
        "newestOnTop": true,

        "position": "top-right",
        "timeout": 5000,
        "closeOnClick": true,
        "pauseOnFocusLoss": true,
        "pauseOnHover": true,
        "draggable": true,
        "draggablePercent": 0.6,
        "showCloseButtonOnHover": false,
        "hideProgressBar": true,
        "closeButton": "button",
        "icon": true,
        "rtl": false
    }
});

app.mount('#app')
