<template>
    <div class="home" id="main">
        <MainSection :value="jsonFile?.mainSection"/>
        <section class="bg-intuit">
            <template v-for="(item, val, i) in jsonFile?.productInfo" :key="i">
                <ProductDescription :class="{'section-description__custom': i%2}"
                                    :value="item"
                                    v-if="i < 2"
                />
            </template>
        </section>
        <LogoSlider :value="jsonFile?.partners"/>
<!--        <TitleComponent :value="jsonFile?.titleSection"/>-->
        <ProductInfo v-for="(item, val, i) in jsonFile?.products"
                     :key="i"
                     :class="{
                         'section-product__revers': i%2 != 0,
                         'section-product__revers-image': i%2 == 0
                     }"
                     :value="item"
        />
        <ContactComponent/>
    </div>
</template>

<script>

export default {
    name: 'HomeView',
    components: {
    },
    data() {
        return {
            jsonFile: {},
        }
    },
    created() {
        this.jsonFile = require('../../pages/main-page.json')
    }
}
</script>
