<template>
    <ul class="social-network">
        <li class="social-network__item" v-for="(item, i) in socialNetwork" :key="i">
            <a class="social-network__link" :href="item.url" :title="item.title" target="_blank">
                <img :src="require('@/assets/' + item.icon + '.svg')" :alt="item.title">
            </a>
        </li>
    </ul>
</template>

<script>
export default {
    name: "SocialNetwork",
    data() {
        return {
            socialNetwork: [
                {
                    title: 'Facebook',
                    icon: 'facebook',
                    url: 'https://www.facebook.com/telematixs',
                },
                // {
                //     title: 'Instagram',
                //     icon: 'instagram',
                //     url: 'https://www.instagram.com/',
                // },
                {
                    title: 'Linkedin',
                    icon: 'linkedin',
                    url: 'https://linkedin.com/in/telematixs-provider-085386275',
                },
                // {
                //     title: 'YouTube',
                //     icon: 'youtube',
                //     url: 'https://www.youtube.com/',
                // },
            ]
        }
    }
}
</script>

<style scoped>

</style>