<template>
    <div class="select"
         :class="{'select--active': active}"
         @mouseover="active = true"
         @mouseout="active = false"
    >
        <div class="select__value">{{ list[modelValue]?.title }}</div>
        <ul class="select__list">
            <li v-for="(lang, val, i) in list"
                class="select__item"
                :key="i"
                @click="$emit('update:modelValue', val)">
                {{ lang.title }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: "customSelect",
    emits:['update:modelValue'],
    props: {
        'list': [Array, Object],
        'modelValue': String
    },
    data() {
        return {
            active: false
        }
    }
}
</script>

<style scoped>

</style>