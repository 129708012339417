<template>
    <header class="header">
        <div class="wrap header__wrap">
            <a class="header__logo" href="/">
                <img :src="require('@/assets/logo.png')" alt="Telematixs">
            </a>
            <HamburgerButton v-model:active="mobileMenuActive"/>
            <nav class="header__nav" :class="{'header__nav-mobile': mobileMenuActive}">
                <div class="header__nav-wrap">
                    <ul class="header__nav-list">
                        <template v-for="(item, i) in menu" :key="i">
                            <li class="header__nav-item"
                                :class="{'header__nav-item-list': item?.list?.length, 'header__nav-item-list--active': item.title == open}"
                                @mouseover="open = item.title"
                                @mouseout="open = null"
                                @click="activeLink = item.title"
                            >
                                <a class="nav-item-title"
                                   :class="[ ((item.title == 'Main' && !activeLink) || activeLink == item.title) && 'link-active']"
                                   @click="setLink(item.url, i)"
                                >
                                    {{ item.title }}
                                </a>
                                <ul class="header__nav-item-menu" v-if="item?.list?.length">
                                    <li class="header__nav-item-menu__item" v-for="(subItem, i) in item?.list" :key="i">
                                        <a
                                            class="header__nav-item-menu__item-link"
                                            @click="setLink(subItem.url, i)"
                                        >
                                            {{ subItem.title }}
                                        </a>
                                    </li>
                                </ul>
                            </li>
                        </template>
                    </ul>
                    <CustomSelect v-model="lang" :list="langs"/>
                </div>
                <SocialNetwork/>
            </nav>
        </div>
    </header>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

export default {
    name: "HeaderComponent",
    components: {},

    data() {
        return {
            open: null,
            activeLink: null,
            mobileMenuActive: false,
            langs: {
                en: {
                    title: 'Eng',
                },
                es: {
                    title: 'Esp',
                },
                // pt: {
                //     title: 'Pt',
                // }
            },
            menu: [
                {
                    title: 'Main',
                    url: '#app',
                    list: [],
                },
                {
                    title: 'Products',
                    url: '',
                    list: [
                        {
                            title: 'xVision',
                            url: '#vision',
                        },
                        {
                            title: 'xTrack',
                            url: '#track',
                        },
                        {
                            title: 'xCargo',
                            url: '#cargo',
                        },
                        {
                            title: 'xIot',
                            url: '#iot',
                        },
                        // {
                        //     title: 'xData',
                        //     url: '#data',
                        // }
                    ],
                },
                {
                    title: 'Contacts',
                    url: '#contacts',
                    list: [],
                }
            ]
        }
    },
    computed: {
        ...mapGetters([
            "getLang"
        ]),
        lang: {
            get() { return this.getLang },
            set(lang) { this.setLang(lang) }
        }
    },
    methods: {
        ...mapMutations([
            "setLang"
        ]),
        setLink(link, i){
            let block = 'center'
            this.activeLink = i;
            if(!link) return;
            if(link == '#app') {
                block = "start"
            }
            document.querySelector(link).scrollIntoView({ behavior : "smooth" , block})
        },
    }
}
</script>

<style scoped>

</style>