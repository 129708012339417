<template>
    <section class="section-title">
        <h2 class="section-title__title">{{ title }}</h2>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "TitleSection",
    props:{
        value: Object
    },
    computed:{
        ...mapGetters([
            "getLang"
        ]),
        title() {
            return this.value?.title[this.getLang]
        }
    }
}
</script>

<style scoped>

</style>