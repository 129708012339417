<template>
    <section class="contact-form">
        <div class="contact-form__wrap">
            <button class="contact-form__close" @click="setForm(false)"></button>
            <div class="contact-form__scroll-wrap" v-if="!formSend">
                <h2 class="contact-form__title">Contact form</h2>
                <form class="contact-form__body" id="contactForm" name="contact_form" @submit.prevent="sendEmail">
                    <ul class="contact-form__block">
                        <li class="contact-form__item" v-for="(element, i) in form" :key="i">
                            <label class="contact-form__item__label">
                                <h3 class="contact-form__item__title">
                                    <span class="contact-form__item__title-badge" v-if="element.required" >*</span>
                                    {{ element.label }}:
                                </h3>
                                <div class="contact-form__item__input-block">
                                    <input
                                        class="contact-form__item__input"
                                        :type="element.type"
                                        :name="element.id"
                                        :required="element.required"
                                        v-model="params[element.id]"
                                        v-if="element.type != 'area'"
                                    >
                                    <textarea v-else
                                              :name="element.id"
                                              :required="element.required"
                                              v-model="params[element.id]">
                                </textarea>
                                </div>
                            </label>
                        </li>
                    </ul>
                    <div class="contact-form__footer">
                        <button type="submit"
                                class="button button__base"
                                :class="[!inProgress && 'button__disabled']"
                                :disabled="!inProgress"
                        >
                            Submit
                        </button>
                    </div>
                </form>
            </div>
            <div v-if="formSend">
                <p style="margin-bottom: 30px">Thank you for your Inquiry a Member of the Sales Department will be in contact Shortly</p>
                <button class="button button__base" style="margin: 0 auto" @click="setForm(false)">Ok</button>
            </div>
        </div>
    </section>
</template>

<script>

import axios from 'axios';
import { useToast } from "vue-toastification";
import {mapMutations} from "vuex";

const def_params = {
    name: '',
    phone: '',
    email: '',
    comments: '',
};
export default {
    name: "ContactForm",
    data() {
        return {
            inProgress: true,
            toast: useToast(),
            formSend: false,
            form: [
                {
                    id: 'first_mame',
                    label: 'First Name',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'last_mame',
                    label: 'Last Name',
                    type: 'text',
                    required: true,
                },
                {
                    id: 'phone',
                    label: 'Phone number',
                    type: 'tel',
                    required: true,
                },
                {
                    id: 'email',
                    label: 'E-mail',
                    type: 'email',
                    required: true,
                },
                {
                    id: 'company',
                    label: 'Company ',
                    type: 'text',
                    required: false,
                },
                {
                    id: 'comments',
                    label: 'Comments',
                    type: 'area',
                    required: false,
                },
            ],
            params: {...def_params}
        }
    },
    methods: {
        ...mapMutations([
            "setForm"
        ]),
        async sendEmail() {
            this.inProgress = false
            // (optional) Wait until recaptcha has been loaded.
            await this.$recaptchaLoaded()
            // Execute reCAPTCHA with action "login".
            const token = await this.$recaptcha('sendEmail')

            // Do stuff with the received token.
            // console.log('recaptcha', token)

            axios({
                method: 'post',
                url: "/email.php",
                data: {...this.params, token},
            })
                .then((resp) => {
                    // console.log('good', resp)
                    if(resp.data.error){
                        console.error(resp.data.message)
                        this.toast.error(resp.data.message)

                        //'<div class="alert alert-danger"> Error! The security token has expired or you are a bot.</div>'
                    } else {
                        console.log(resp.data.message)
                        this.toast.success(resp.data.message)
                        // '<p class="alert alert-success">Thank You! Your message has been successfully sent.</p>';
                        this.params = {...def_params}
                    }
                    this.inProgress = true
                    this.formSend = true
                })
                .catch((error) => {
                    console.warn(error)
                    //if(error.response.status == 403)
        /*            //if(error.response.status == 500)*/
                    {
                        let message = "Something went wrong, your message could not be sent."
                        this.toast.error(message)
                        // 500:
                        // <p className="alert alert-warning">Something went wrong, your message could not be sent.</p>
                        // 403:
                        // <p className="alert-warning">There was a problem with your submission, please try again.</p>
                    }
                    this.inProgress = true
                })
            // return false;
        },
    },
}
</script>

<style scoped>

</style>