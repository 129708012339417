<template>
    <section class="section-description">
        <div class="wrap section-description__block">
            <div class="section-description__block-item section-description__block-title">
                <h3 class="section-description__title">{{ title }}</h3>
            </div>
            <div class="section-description__block-item section-description__block-description">
                {{ description }}
            </div>
        </div>
    </section>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "ProductDescription",
    props: {
        value: Object,
    },
    computed: {
        ...mapGetters([
            "getLang"
        ]),
        title() {
            return this.value?.title[this.getLang]
        },
        description() {
            return this.value?.description[this.getLang]
        }
    }
}
</script>

<style scoped>

</style>