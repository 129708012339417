<template>
    <section class="section-slider">
        <div class="section-slider__wrap">
            <swiper class="section-slider__swiper" v-bind="swiperOptions">
                <swiper-slide class="section-slider__slide" v-for="(partner, val, i) in value" :key="i">
                    <a :href="partner.url" target="_blank" class="section-slider__link">
                        <img :class="partner?.class" class="section-slider__icon" :src="require('@/assets/'+ partner.icon +'.png')" :alt="partner.title" :title="partner.title">
                    </a>
                </swiper-slide>
            </swiper>
        </div>
    </section>

</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/autoplay'
import { Autoplay, } from "swiper";
export default {
    name: "LogoSlider",
    props: {
        value: Object,
    },
    components: {
        Swiper,
        SwiperSlide,
    },
    data() {
        return {
            swiperOptions:{
                centeredSlides: true,
                slidesPerView: 5,
                loopedSlides: 2,
                spaceBetween: 10,
                autoHeight: false,
                autoplay: {
                    delay: 2500,
                    disableOnInteraction: false,
                },
                breakpoints:{
                    0:{
                        slidesPerView: 2,
                        centeredSlides: false,
                    },
                    1000:{
                        slidesPerView: 3
                    },
                    1380:{
                        slidesPerView: 4,
                    },
                    1800:{
                        slidesPerView: 5
                    }
                },
                loop: true,
                observe: true,
                observeParents: true,
                observeSlideChildren: true,
                speed:1000,
                modules:[Autoplay]
            }
        }
    }
}
</script>

<style scoped>

</style>